<template>
  <div>
    <div class="row">
      <div class="col-3">
        <label for="">Reference</label>
        <input
          type="text"
          :disabled="true"
          :value="user.employee_reference"
          class="form-control"
        />
      </div>
      <div class="col-3">
        <label for="">Nom Complet</label>
        <select
          v-model="user.employee_reference"
          name=""
          id=""
          class="form-select"
          :disabled="user.employee_reference == null ? true : false"
        >
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Role</label>
        <select
          name=""
          v-model="user.user_role_reference"
          id=""
          class="form-select"
        >
          <option
            v-for="(item, index) in user_roles"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Username</label>
        <input type="text" v-model="user.username" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Password</label>
        <input type="text" v-model="user.password" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Access</label>
        <select name="" v-model="user.access" id="" class="form-select">
          <option :value="1">Autrosie</option>
          <option :value="0">Bloque</option>
        </select>
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(user)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // user: {},
    };
  },
  computed: {
    ...mapGetters("user", {
      user_roles: "getUserRoles",
      user: "getUser",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("user/update", data);
    },
  },
  beforeMount() {
    this.$store.dispatch("employee/getAll");
    this.$store.dispatch("user/getUserRoles");

    this.$store.dispatch("user/show", this.$route.params.reference);
  },
};
</script>
